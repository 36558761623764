import React from "react"
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const { Config } = require("../../config.js")

const paperStyle = {
  marginTop: "64px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}

const avatarStyle = {
  margin: "8px",
}

const formStyle = {
  width: "80%", // Fix IE 11 issue.
  marginTop: "8px",
}

const submitStyle = {
  marginTop: "24px",
  marginBottom: "16px",
}

const imageStyle = { marginTop: "64px" }

class ResetPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loginError = false
  }

  handleSubmit(event) {
    const stringify = require("json-stringify-safe")
    var data = stringify(this.state)
    fetch(Config.route + "/api/forgot-password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    })
    .then(res => {
      if (res.ok) {
        navigate("/set-new-password")
      } else {
        this.loginError = "true"
        navigate("/reset-password")
      }
    })
    .catch(err => console.log("Request Failed", err))

    return false
  }

  handleChange(event) {
    switch (event.target.name) {
      case "email":
        this.setState({ email: event.target.value })
    }
  }

  render() {
    return (
      <>
        <Seo title="Forgot Password" />
        <Layout>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CssBaseline />
                <div style={paperStyle}>
                  <Avatar style={avatarStyle}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Reset Password
                  </Typography>
                  <form style={formStyle}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={this.handleChange}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={submitStyle}
                      onClick={this.handleSubmit}
                    >
                      Send Email OTP
                    </Button>
                    <Grid container>
                      <Grid item>
                        <Link href="/sign-up" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                    {this.loginError ? (
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Alert severity="error" sx={submitStyle}>
                            <AlertTitle>Error</AlertTitle>
                            Invalid Email address. —{" "}
                            <strong>Please try again!</strong>
                          </Alert>
                        </Grid>
                      </Grid>
                    ) : null}
                  </form>
                </div>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <div style={imageStyle}>
                    <img
                      src={require("../images/Unlock.svg").default}
                      width="100%"
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </>
    )
  }
}

export default ResetPassword
